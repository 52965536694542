@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';








.unknownIcon-root {
  color: black;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}
